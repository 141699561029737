import { useSupabaseClient, useUser } from '@supabase/auth-helpers-react'
import { useQuery } from '@tanstack/react-query'
import { isCommittedInvestor } from 'integrations/supabase/calls/isCommittedInvestor'

export const useIsCommittedInvestor = (offeringId: string) => {
  const supabase = useSupabaseClient()
  const user = useUser()

  const queryResult = useQuery({
    queryKey: ['committedInvestor', offeringId],
    enabled: !!offeringId && !!user,
    queryFn: async () => {
      if (!offeringId) return null
      return isCommittedInvestor(supabase, offeringId)
    },
  })
  return { ...queryResult, data: queryResult.data ?? false }
}
