import { LinkButton } from 'components/atoms/LinkButton'
import { Button } from 'components/atoms/Button'
import styled from 'styled-components'
import { Tooltip } from 'components/atoms/Tooltip'
import { OfferingButtonWrapper } from '../OfferingButtons'
import { CapitalRaisingStrategy } from 'components/Portfolios/OfferingForm/types'

interface CommonButtonProps {
  className?: string
  tabIndex?: number
  capitalRaisingStrategy: CapitalRaisingStrategy
  isCommittedInvestor: boolean | undefined
}

interface EnabledButtonProps extends CommonButtonProps {
  portfolioAddress: string
}

export function EnabledInvestButton({
  portfolioAddress,
  className,
  tabIndex,
  capitalRaisingStrategy,
  isCommittedInvestor,
}: EnabledButtonProps) {
  return (
    <OfferingButtonWrapper>
      <LinkButton
        className={className}
        href={getHref(capitalRaisingStrategy, portfolioAddress)}
        view="primary"
        wide
        tabIndex={tabIndex}
      >
        {getButtonName(capitalRaisingStrategy, isCommittedInvestor)}
      </LinkButton>
    </OfferingButtonWrapper>
  )
}

interface DisabledButtonProps extends CommonButtonProps {
  disabledMessage: string
}

export function DisabledInvestButton({
  disabledMessage = 'This Offering is not accepting investments.',
  className,
  tabIndex,
  capitalRaisingStrategy,
  isCommittedInvestor,
}: DisabledButtonProps) {
  return (
    <InvestTooltip side="bottom" tooltip={disabledMessage}>
      <OfferingButtonWrapper>
        <Button className={className} view="primary" disabled wide tabIndex={tabIndex}>
          {getButtonName(capitalRaisingStrategy, isCommittedInvestor)}
        </Button>
      </OfferingButtonWrapper>
    </InvestTooltip>
  )
}

const getButtonName = (capitalRaisingStrategy: CapitalRaisingStrategy, isCommittedInvestor: boolean | undefined) => {
  switch (capitalRaisingStrategy) {
    case 'CapitalCalls':
      return isCommittedInvestor ? 'Deposit' : 'Commit'
    default:
      return 'Invest'
  }
}

function getHref(capitalRaisingStrategy: CapitalRaisingStrategy, portfolioAddress: string) {
  const page = capitalRaisingStrategy === 'CapitalCalls' ? 'capital-call/commit' : 'invest'
  return `/offering/${portfolioAddress}/${page}`
}

const InvestTooltip = styled(Tooltip)`
  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    width: 100%;
  }
  overflow: unset;
`
