import { AddIcon } from 'components/atoms/Icons'
import { LinkButton } from 'components/atoms/LinkButton'
import styled from 'styled-components'
import { isFeatureEnabled } from 'utils'
import { LaunchOfferingButton } from './LaunchOfferingButton'
import { OfferingButtonWrapper } from '../OfferingButtons'
import { isAssetOffering, isCreditOffering, Offering } from 'types/Portfolio/StructuredOffering'
import { ManagerWalletTooltip } from './ManagerWalletTooltip'
import { useIsManagerWalletConnected } from 'hooks'

interface Props {
  portfolio: Offering
}

export function ManagerButtons({ portfolio }: Props) {
  const buttons: JSX.Element[] = []
  const isManagerWalletConnected = useIsManagerWalletConnected(portfolio)

  if (portfolio.status === 'Fundraising') {
    buttons.push(<LaunchOfferingButton portfolio={portfolio} key="launch-offering" />)
  }

  if (portfolio.status === 'Open') {
    if (isCreditOffering(portfolio) && isFeatureEnabled('createLoanFlow')) {
      buttons.push(
        <CreateLoanButton
          address={portfolio.address}
          key="create-loan"
          isManagerWalletConnected={isManagerWalletConnected}
        />,
      )
    }

    if (isAssetOffering(portfolio) && isFeatureEnabled('assetVaults')) {
      buttons.push(
        <CapitalUpdateButton
          address={portfolio.address}
          key="capital-update"
          isManagerWalletConnected={isManagerWalletConnected}
        />,
      )
    }
  }

  if (buttons.length < 1) {
    return null
  }

  return <OfferingButtonWrapper>{buttons}</OfferingButtonWrapper>
}

interface CreateLoanButtonProps {
  address: string
  isManagerWalletConnected: boolean
}

function CreateLoanButton({ address, isManagerWalletConnected }: CreateLoanButtonProps) {
  return (
    <ManagerWalletTooltip isManagerWalletConnected={isManagerWalletConnected}>
      <StyledButton
        href={`/offering/${address}/loans/create`}
        view="primary"
        icon={<AddIcon />}
        wide
        disabled={!isManagerWalletConnected}
      >
        Create Loan
      </StyledButton>
    </ManagerWalletTooltip>
  )
}

function CapitalUpdateButton({ address, isManagerWalletConnected }: CreateLoanButtonProps) {
  return (
    <ManagerWalletTooltip isManagerWalletConnected={isManagerWalletConnected}>
      <LinkButton href={`/offering/${address}/capital-update`} view="primary" disabled={!isManagerWalletConnected}>
        Capital Updates
      </LinkButton>
    </ManagerWalletTooltip>
  )
}

const StyledButton = styled(LinkButton)`
  padding: 8px 16px 8px 40px;
  min-width: 150px;
`
