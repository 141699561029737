import { isPotentialOffering, PotentialOffering } from 'types/Portfolio'
import { InvestButton } from './InvestButton'
import { isFeatureEnabled } from 'utils'
import { ManagerButtons } from './ManagerButtons'
import styled from 'styled-components'
import { useInvestStatus } from 'hooks/calls'
import { Offering } from 'types/Portfolio/StructuredOffering'
import { LinkButton, LinkButtonContainer } from 'components/atoms/LinkButton'
import { EditIcon } from 'components/atoms/Icons/IconsFiles/EditIcon'
import { useIsOfferingManager } from 'hooks/offering/useIsOfferingManager'
import { PlayCircleIcon } from 'components/atoms/Icons'
import { useEthers } from '@usedapp/core'
import { Tooltip } from 'components/atoms/Tooltip'
import { useWidthBreakpoint } from 'hooks'

interface Props {
  portfolio: Offering | PotentialOffering
}

export function OfferingButtons({ portfolio }: Props) {
  const buttons: JSX.Element[] = []
  const { account } = useEthers()
  const isManager = useIsOfferingManager(portfolio)
  const mobileLayout = useWidthBreakpoint('mobileAndSmaller')

  if (!isPotentialOffering(portfolio)) {
    return <RealPortfolioOfferingButtons portfolio={portfolio} isManager={isManager} />
  }

  if (isPotentialOffering(portfolio) && isManager) {
    if (isFeatureEnabled('editOfferingFlow')) {
      buttons.push(<EditOfferingButton id={portfolio.id} key="edit-offering" wide={mobileLayout} />)
    }

    if (isFeatureEnabled('startOfferingFlow')) {
      if (!account) {
        buttons.push(
          <WideTooltip key="start-offering" tooltip="Connect your wallet to take full advantage of the features">
            <StartOfferingButton wide={mobileLayout} id={portfolio.id} disabled />
          </WideTooltip>,
        )
      } else {
        buttons.push(<StartOfferingButton wide={mobileLayout} id={portfolio.id} />)
      }
    }
  }

  if (buttons.length === 0) {
    return null
  }

  return <OfferingButtonWrapper>{buttons}</OfferingButtonWrapper>
}

interface RealPortfolioOfferingButtonsProps {
  portfolio: Offering
  isManager: boolean
}

const RealPortfolioOfferingButtons = ({ portfolio, isManager }: RealPortfolioOfferingButtonsProps) => {
  const { investStatus } = useInvestStatus(portfolio)

  if (isManager) {
    return <ManagerButtons portfolio={portfolio} />
  }

  return <InvestButton portfolio={portfolio} investStatus={investStatus} />
}

const EditOfferingButton = ({ id, wide }: { id: string; wide: boolean }) => {
  return (
    <StyledButton href={`/offering/${id}/edit`} view="secondary" icon={<EditIcon />} iconPosition="left" wide={wide}>
      Edit Offering
    </StyledButton>
  )
}

const StartOfferingButton = ({ id, disabled, wide }: { id: string; disabled?: boolean; wide: boolean }) => {
  return (
    <StyledButton
      href={{ pathname: '/offering/create', query: { offeringId: id } }}
      view="secondary"
      icon={<PlayCircleIcon />}
      iconPosition="left"
      wide={wide}
      disabled={disabled}
    >
      Start Offering
    </StyledButton>
  )
}

const WideTooltip = styled(Tooltip)`
  width: 100%;
`

export const OfferingButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    width: 100%;
    gap: 32px;
    flex-direction: column-reverse;
  }
`

const StyledButton = styled(LinkButton)`
  padding: 8px 16px 8px 40px;
  min-width: 150px;

  @media ${({ theme }) => theme.queries.mobileAndSmaller} {
    width: 100%;
    padding: 0;

    ${LinkButtonContainer} {
      width: 100%;
      min-width: auto;
      max-width: unset;
    }
  }
`
