import { ABI_VIEWS } from 'types/abi'
import { ContractFunctionArgs, useChainCall, useChainCalls } from './useChainCalls'
import { TRANCHE_VAULT_ABI, TRANCHE_VAULT_INTERFACE } from 'constants/abis/carbon/TrancheVault'
import { SupportedChainId } from 'constants/chain'

export function useTrancheVaultCalls<T>(
  chainId: SupportedChainId | undefined,
  addresses: (string | undefined)[],
  functionName: ABI_VIEWS<typeof TRANCHE_VAULT_ABI>,
  args: ContractFunctionArgs[] = [],
  skip?: boolean,
): (T | undefined)[] {
  const results = useChainCalls<T>(chainId, addresses, functionName, TRANCHE_VAULT_INTERFACE, args, skip) ?? []
  return results.map((result) => result.data?.[0])
}

export function useTrancheVaultCall<T>(
  chainInfo: { chainId: SupportedChainId | undefined; address: string } | undefined | null,
  functionName: ABI_VIEWS<typeof TRANCHE_VAULT_ABI>,
  args: unknown[] = [],
  skip?: boolean,
): T | undefined {
  return useChainCall<T>(chainInfo?.chainId, chainInfo?.address, functionName, TRANCHE_VAULT_INTERFACE, [args], skip)
    .data?.[0]
}
