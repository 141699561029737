import { ABI_VIEWS } from 'types/abi'
import { PORTFOLIO_ABI, PORTFOLIO_INTERFACE } from 'constants/abis'
import { useChainCall, useChainCalls } from './useChainCalls'
import { SupportedChainId } from 'constants/chain'

export function usePortfolioCalls<T>(
  chainId: SupportedChainId | undefined,
  addresses: (string | undefined)[],
  functionName: ABI_VIEWS<typeof PORTFOLIO_ABI>,
  args: unknown[] = [],
  skip?: boolean,
): (T | undefined)[] {
  const results = useChainCalls<T>(chainId, addresses, functionName, PORTFOLIO_INTERFACE, [args], skip) ?? []
  return results.map((result) => result.data?.[0])
}

export function usePortfolioCall<T>(
  chainInfo: { chainId: SupportedChainId | undefined; address: string } | undefined | null,
  functionName: ABI_VIEWS<typeof PORTFOLIO_ABI>,
  args: unknown[] = [],
  skip?: boolean,
): T | undefined {
  return useChainCall<T>(chainInfo?.chainId, chainInfo?.address, functionName, PORTFOLIO_INTERFACE, [args], skip)
    .data?.[0]
}
