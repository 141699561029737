import { sendExceptionToSentry } from 'utils/api/sendExceptionToSentry'
import { SupabaseClient } from '@supabase/supabase-js'

export async function isCommittedInvestor(supabase: SupabaseClient, offeringId: string): Promise<boolean> {
  try {
    const { data } = await supabase
      .rpc('is_committed_investor', {
        offering_id: offeringId,
      })
      .throwOnError()

    return data
  } catch (error) {
    await sendExceptionToSentry(error)

    return false
  }
}
