import { useDepositControllerCall, usePortfolioCall } from 'hooks/calls'
import { Offering } from 'types/Portfolio/StructuredOffering'

export function useDepositAllowed(portfolio: Offering) {
  const { address, chainId } = portfolio
  const depositControllerAddress = portfolio.tranches[0].depositController

  const portfolioStatus = usePortfolioCall({ chainId, address }, 'status')
  const depositAllowed = useDepositControllerCall<boolean>(
    { chainId, address: depositControllerAddress },
    'depositAllowed',
    [portfolioStatus],
    !depositControllerAddress,
  )

  return depositAllowed
}
