import styled, { css } from 'styled-components'
import { BorderRadiuses, shadows, zIndexes } from 'styles'
import { Column } from 'components/atoms/Column'
import { TableCellStyled } from './TableCell'

export const TableContainer = styled.div`
  width: 100%;
  position: relative;
  min-width: 100%;
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: ${({ theme: { colors } }) => `${colors.Iron} ${colors.Mouse}`};
  border-right: 1px solid ${({ theme }) => theme.colors.Mouse};

  &::-webkit-scrollbar {
    display: block;
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: ${({ theme }) => theme.colors.Mouse};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.Iron};
  }
`

export const Table = styled.table`
  width: 100%;
  max-width: 100%;
  border-spacing: initial;
  background-color: ${({ theme }) => theme.colors.White};
  border-radius: ${BorderRadiuses.s};
  border-collapse: separate;
  table-layout: fixed;

  @media ${({ theme }) => theme.queries.desktopAndSmaller} {
    table-layout: auto;
  }
`

export const TableBody = styled.tbody`
  max-width: 100%;
  overflow: hidden;
`

export interface TableRowProps {
  disableShadow?: boolean
  disablePointerCursor?: boolean
}

export const TableRow = styled.tr<TableRowProps>`
  position: relative;
  max-width: 100%;
  background-color: ${({ theme }) => theme.colors.White};
  ${({ disablePointerCursor }) =>
    disablePointerCursor
      ? ''
      : css`
          cursor: pointer;
        `}

  &:last-child {
    ${TableCellStyled}:first-child {
      border-radius: 0px 0px 0px ${BorderRadiuses.s};
    }

    ${TableCellStyled}:last-child {
      border-radius: 0px 0px ${BorderRadiuses.s} 0px;
    }
  }

  ${({ disableShadow }) =>
    !disableShadow &&
    `
    &:hover {
      box-shadow: ${shadows.s};
      z-index: ${zIndexes.content};
    }
  `}
`

export const TableRowExpanded = styled.tr`
  position: relative;
  background-color: ${({ theme }) => theme.colors.White};
`

export const TableWrapper = styled(Column)`
  row-gap: 16px;
`
