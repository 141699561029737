import { TransactionStatus } from '@usedapp/core'

export type SupportedTransactionStatus = 'Rejected' | 'Success' | 'Fail' | 'Pending' | 'PendingSignaturePool' | 'None'

export const getTransactionStatus = (state: TransactionStatus): SupportedTransactionStatus => {
  switch (state.status) {
    case 'Exception':
      return state.errorMessage === 'user rejected transaction' ? 'Rejected' : 'Fail'
    case 'PendingSignature':
    case 'Mining':
      return 'Pending'
    case 'CollectingSignaturePool':
      return 'PendingSignaturePool'
    default:
      return state.status
  }
}
