import { LinkButton } from 'components/atoms/LinkButton'
import { Button } from 'components/atoms/Button'
import { ArrowForwardIcon } from 'components/atoms/Icons'
import styled from 'styled-components'
import { StatusModalChildrenProps } from 'components/organisms/Modals'

export const SuccessButtons = ({ closeModal }: StatusModalChildrenProps) => (
  <ButtonsRow>
    <LinkButton view="tertiary" icon={<ArrowForwardIcon />} iconPosition="right" href="/dashboard">
      My Offerings
    </LinkButton>
    <Button view="tertiary" icon={<ArrowForwardIcon />} iconPosition="right" onClick={closeModal}>
      Offering
    </Button>
  </ButtonsRow>
)

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  column-gap: 24px;
`
