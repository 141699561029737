import { useUser } from '@supabase/auth-helpers-react'
import { DisabledInvestButton, EnabledInvestButton } from './components'
import { InvestStatus } from 'hooks/calls'
import { Offering } from 'types/Portfolio/StructuredOffering'
import { useIsCommittedInvestor } from 'hooks/offering/useIsCommittedInvestor'

interface Props {
  portfolio: Offering
  className?: string
  tabIndex?: number
  investStatus: InvestStatus
}

const SIGN_IN_MESSAGE = 'Sign in to invest'

const COMMITTED_MESSAGE =
  'You have already committed to this offering. When the manager creates a new capitall call you will be notified via email to deposit funds.'

const DISABLED_INVEST_MESSAGES: Record<InvestStatus, string> = {
  allowed: '',
  lenderRestricted:
    'This Offering is available to non-US investors, who have completed onboarding with the manager entity.',
  depositNotAllowed: 'This Offering is not accepting investments.',
  accountNotConnected:
    'This Offering is available to non-US investors, who have completed onboarding with the manager entity. Connect wallet to check your status.',
}

export function InvestButton({ portfolio, className, tabIndex, investStatus }: Props) {
  const user = useUser()
  const isSignedIn = !!user
  const { data: isCommittedInvestor } = useIsCommittedInvestor(portfolio.id)

  const commonProps = {
    capitalRaisingStrategy: portfolio.capitalRaisingStrategy,
    tabIndex,
    className,
    isCommittedInvestor,
  }

  if (isCommittedInvestor) {
    return <DisabledInvestButton disabledMessage={COMMITTED_MESSAGE} {...commonProps} />
  }

  if (investStatus === 'allowed') {
    return isSignedIn ? (
      <EnabledInvestButton portfolioAddress={portfolio.address} {...commonProps} />
    ) : (
      <DisabledInvestButton disabledMessage={SIGN_IN_MESSAGE} {...commonProps} />
    )
  }

  return <DisabledInvestButton disabledMessage={DISABLED_INVEST_MESSAGES[investStatus]} {...commonProps} />
}
