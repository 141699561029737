import { BigNumber } from 'ethers'
import { useTrancheVaultCalls } from 'hooks/calls'
import { useStructuredPortfolioFunction } from 'hooks/transactions'
import { canLaunchOffering } from 'domain/offering/canLaunchOffering'
import { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { LaunchOfferingModal, TransactionModal } from 'components/organisms/Modals'
import { Button } from 'components/atoms/Button'
import { getTransactionStatus } from 'utils/transactions'
import { SuccessButtons } from './SuccessButtons'
import { useNotifications } from 'providers/NotificationsProvider'
import { useIsManagerWalletConnected, useStorage } from 'hooks'
import { Offering } from 'types/Portfolio/StructuredOffering'
import { ManagerWalletTooltip } from './ManagerWalletTooltip'

interface Props {
  portfolio: Offering
}

export function LaunchOfferingButton({ portfolio }: Props) {
  const isManagerWalletConnected = useIsManagerWalletConnected(portfolio)
  const [isLaunchModalOpen, setIsLaunchModalOpen] = useState<boolean>(false)
  const { tranches, minimumSize, chainId, address } = portfolio
  const tranchesAddresses = tranches.map(({ address }) => address)
  const tranchesTotalAssets = useTrancheVaultCalls<BigNumber>(chainId, tranchesAddresses, 'totalAssets')
  const isTargetSizeMet = useMemo(
    () => canLaunchOffering(tranchesTotalAssets, minimumSize),
    [minimumSize, tranchesTotalAssets],
  )
  const { send, state: transactionState, resetState } = useStructuredPortfolioFunction(address, 'start')
  const launchOffering = useCallback(async () => {
    setIsLaunchModalOpen(false)
    await send([])
  }, [send])
  const transactionStatus = useMemo(() => getTransactionStatus(transactionState), [transactionState])
  const { addNotification } = useNotifications()
  const [isLaunched, setIsLaunched] = useStorage<boolean>(`${portfolio.address}-launched`)

  useEffect(() => {
    if (transactionStatus === 'Rejected') {
      resetState()
      setIsLaunchModalOpen(true)
    }

    if (transactionStatus === 'Success') {
      addNotification({
        type: 'info',
        description: `Your offering ${portfolio.name} will soon change its status to "Open".`,
        title: 'Processing',
        duration: 60000,
      })
      setIsLaunched(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetState, transactionStatus])

  const isButtonDisabled =
    !isTargetSizeMet || transactionStatus === 'Success' || isLaunched || !isManagerWalletConnected

  return (
    <ManagerWalletTooltip isManagerWalletConnected={isManagerWalletConnected}>
      <LaunchButton onClick={() => setIsLaunchModalOpen(true)} view="primary" disabled={isButtonDisabled} wide>
        Launch Offering
      </LaunchButton>
      <LaunchOfferingModal onConfirm={launchOffering} opened={isLaunchModalOpen} toggleOpened={setIsLaunchModalOpen} />
      <TransactionModal status={transactionStatus} transactionHash={transactionState.transaction?.hash}>
        {transactionStatus === 'Success' && SuccessButtons}
      </TransactionModal>
    </ManagerWalletTooltip>
  )
}

const LaunchButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.Emerald};
  border-color: transparent;
`
