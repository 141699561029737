import { Contract } from 'ethers'
import { Call, Falsy, useCall, useEthers } from '@usedapp/core'
import { useGetPortfolioRestriction } from 'hooks'
import { useDepositAllowed } from 'hooks/useDepositAllowed'
import { Offering } from 'types/Portfolio/StructuredOffering'

export type InvestStatus = 'allowed' | 'lenderRestricted' | 'depositNotAllowed' | 'accountNotConnected'

export function useInvestStatus(portfolio: Offering): { investStatus: InvestStatus; isLoading: boolean } {
  const { account } = useEthers()

  const { restriction, check } = useGetPortfolioRestriction(portfolio)
  const depositAllowed = useDepositAllowed(portfolio)

  const call: Falsy | Call = account &&
    check?.callData && {
      args: check.callData.args,
      contract: new Contract(check.callData.address, check.callData.abi),
      method: check.callData.functionName,
    }
  const isAllowedByContract: boolean[] | undefined = useCall(call, { chainId: portfolio.chainId })?.value
  const isVerifying = !!call && isAllowedByContract === undefined
  const isLoading = isVerifying || depositAllowed === undefined
  const isAllowed = restriction === 'AllowAll' || (isAllowedByContract && isAllowedByContract[0])

  function getStatus() {
    switch (true) {
      case !account:
        return 'accountNotConnected'
      case !depositAllowed:
        return 'depositNotAllowed'
      case isAllowed:
        return 'allowed'
      default:
        return 'lenderRestricted'
    }
  }

  return { isLoading, investStatus: getStatus() }
}
