import { useSupabaseClient } from '@supabase/auth-helpers-react'
import { useEffect, useState } from 'react'
import { Database } from 'integrations/supabase/generated.types'
import { isOfferingManager } from 'integrations/supabase/calls/isOfferingManager'
import { Offering } from 'types/Portfolio/StructuredOffering'
import { PotentialOffering } from 'types'

export const useIsOfferingManager = (portfolio: Offering | PotentialOffering) => {
  const [isManager, setIsManager] = useState(false)
  const supabase = useSupabaseClient<Database>()

  useEffect(() => {
    const getManager = async () => {
      const manager = await isOfferingManager(supabase, portfolio.id)
      setIsManager(manager)
    }
    void getManager()
  }, [supabase, portfolio.id])

  return isManager
}
