import { Tooltip } from 'components/atoms/Tooltip'
import { ReactNode } from 'react'

interface Props {
  children: ReactNode
  isManagerWalletConnected: boolean
}

export const ManagerWalletTooltip = ({ children, isManagerWalletConnected }: Props) =>
  !isManagerWalletConnected ? (
    <Tooltip side="bottom" tooltip="Connect your manager wallet to take full advantage of your manager account.">
      {children}
    </Tooltip>
  ) : (
    <>{children}</>
  )
