import { Modal } from '../'
import { Button } from 'components/atoms/Button'
import { Text } from 'components/atoms/Text'
import styled from 'styled-components'

export interface LaunchOfferingModalProps {
  onConfirm: () => void
  opened: boolean
  toggleOpened: (arg: boolean) => void
}

export const LaunchOfferingModal = ({ onConfirm, ...modalProps }: LaunchOfferingModalProps) => {
  return (
    <Modal
      {...modalProps}
      title="Launch Offering"
      content={
        <>
          <TextContainer>
            <Text color="dark" bold>
              Launching the offering is required to begin deploying capital.
            </Text>
            <Text color="light">
              Unless you have configured the portfolio to accept deposits after launch, you will no longer be able to
              raise additional capital once this is completed.
            </Text>
          </TextContainer>
          <Button view="primary" wide onClick={onConfirm}>
            Confirm
          </Button>
        </>
      }
    />
  )
}

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`
