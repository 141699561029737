import { useCallback, useContext, useMemo } from 'react'
import { StorageContext } from 'providers/Storage'

export function useStorage<T>(key: string) {
  const { setValue, getValue, removeItem } = useContext(StorageContext)
  const setKeyValue = useCallback((value: T) => setValue(key, value), [key, setValue])
  const value = useMemo(() => getValue(key) as T, [getValue, key])
  const clear = useCallback(() => removeItem(key), [key, removeItem])
  return [value, setKeyValue, clear] as const
}
