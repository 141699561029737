import styled, { css } from 'styled-components'
import { BorderRadiuses, Overflows, Transitions } from 'styles'
import { Row } from 'components/atoms/Row'
import { Column } from 'components/atoms/Column'
import Link from 'next/link'
import { ReactNode } from 'react'
import { PopoverButton } from 'components/atoms/PopoverMenu'

export interface TableCellProps {
  children: ReactNode
  colSpan?: number
  maxWidth?: number
  href?: string
  blank?: boolean
  side?: 'left' | 'right' | 'center'
  selected?: boolean
}

export const TableCell = ({ children, colSpan, maxWidth, href, blank, side, selected }: TableCellProps) => (
  <TableCellStyled selected={selected} maxWidth={maxWidth} {...(colSpan ? { colSpan } : {})}>
    <CellRow maxWidth={maxWidth} side={side}>
      {href && <CellLink href={href} passHref target={blank ? '_blank' : undefined} />}
      {children}
    </CellRow>
  </TableCellStyled>
)

export const TableCellStyled = styled.td<Pick<TableCellProps, 'maxWidth' | 'selected'>>`
  padding: 0;
  color: inherit;
  background-color: inherit;
  border-bottom: 1px solid ${({ theme, selected }) => (selected ? 'none' : theme.colors.Mouse)};
  transition: ${Transitions.all};
  min-width: 135px;
  position: relative;

  ${Overflows.Dots}
  overflow: unset;

  &:first-child {
    border-left: 1px solid ${({ theme }) => theme.colors.Mouse};
  }

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: ${maxWidth + 'px'};
      max-width: ${maxWidth + 'px'};
      text-overflow: ellipsis;
    `}

  ${PopoverButton} {
    top: unset;
  }
`

export const TableCellExpanded = styled.td<{ height?: string }>`
  height: ${({ height }) => `${height}`};
  color: inherit;
  max-width: 100%;
  background-color: inherit;
  border-bottom: 1px solid ${({ theme }) => theme.colors.Mouse};
  border-left: 1px solid ${({ theme }) => theme.colors.Mouse};
`

export const CellRow = styled(Row)<Pick<TableCellProps, 'side' | 'maxWidth'>>`
  height: 108px;
  padding: 14px 16px;
  font-size: 14px;
  line-height: 20px;
  text-align: ${({ side }) => `${side}`};
  border-radius: ${BorderRadiuses.m};
  justify-content: ${({ side }) => {
    switch (side) {
      case 'left':
      default:
        return 'flex-start'
      case 'right':
        return 'flex-end'
      case 'center':
        return 'center'
    }
  }};

  ${({ maxWidth }) =>
    maxWidth &&
    css`
      width: ${maxWidth + 'px'};
      max-width: ${maxWidth + 'px'};
      text-overflow: ellipsis;
    `}
`

const CellLink = styled(Link)`
  text-decoration: none;

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`

export const TableCellColumn = styled(Column)`
  row-gap: 4px;
  width: fit-content;
`

export const TableExpandedRowCell = styled(TableCellStyled)<{ height?: string }>`
  ${({ height }) =>
    height &&
    css`
      height: ${height};
    `}
`
